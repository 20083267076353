<template>
  <div id="app" @click="seeLog">
    <Header
      @stickyUpdate="
        val => {
          stickyHeader = val;
        }
      "
    />
    <div class="content" :class="{ sticky: stickyHeader }">
      <router-view />
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header/Header.vue";
import Footer from "@/components/Footer/Footer.vue";

export default {
  components: {
    Header,
    Footer
  },
  data: function() {
    return {
      stickyHeader: false
    };
  },
  methods: {
    seeLog() {
      console.log("safari mobile focus fix");
    }
  }
};
</script>

<style lang="scss">
#app {
  font-family: PTSansRegular, Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  .content.sticky {
    padding-top: 102px;
  }
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
