<template>
  <nav class="header">
    <div class="secondary-header">
      <div class="grid grid-cols-2 gap-4">
        <div class="text-left">
          <div class="contact-info">
            <span class="contact-info-phone-number text-base"
              >801.567.0456</span
            >
          </div>
        </div>
        <div class="text-right">
          <div class="social-links-header">
            <div class="social-networks boxed-icons">
              <div class="social-networks-wrapper">
                <div style="display:inline-block;">
                  <a
                    class="social-network-icon tooltip facebook icon-facebook"
                    style=""
                    href="https://www.facebook.com/safetymanagementservices"
                    target="_blank"
                    rel="noopener noreferrer"
                    data-placement="top"
                    data-title="facebook"
                    data-toggle="tooltip"
                    title=""
                    data-original-title="facebook"
                  >
                    <svg
                      style="width:20px;fill:white;"
                      viewBox="0 0 50 50"
                      class="icon icons8-Facebook-Filled"
                    >
                      <path
                        d="M40,0H10C4.486,0,0,4.486,0,10v30c0,5.514,4.486,10,10,10h30c5.514,0,10-4.486,10-10V10C50,4.486,45.514,0,40,0z M39,17h-3 c-2.145,0-3,0.504-3,2v3h6l-1,6h-5v20h-7V28h-3v-6h3v-3c0-4.677,1.581-8,7-8c2.902,0,6,1,6,1V17z"
                      ></path>
                    </svg>
                    <span class="screen-reader-text">facebook</span>
                  </a>
                </div>
                <div style="display:inline-block;margin-left:8px;">
                  <a
                    class="social-network-icon tooltip linkedin icon-linkedin"
                    style=""
                    href="https://www.linkedin.com/company/safety-management-services-inc/"
                    target="_blank"
                    rel="noopener noreferrer"
                    data-placement="top"
                    data-title="linkedin"
                    data-toggle="tooltip"
                    title=""
                    data-original-title="linkedin"
                  >
                    <svg style="width:20px;" viewBox="0 50 512 512">
                      <path
                        fill="white"
                        d="M150.65,100.682c0,27.992-22.508,50.683-50.273,50.683c-27.765,0-50.273-22.691-50.273-50.683
                                C50.104,72.691,72.612,50,100.377,50C128.143,50,150.65,72.691,150.65,100.682z M143.294,187.333H58.277V462h85.017V187.333z
                                M279.195,187.333h-81.541V462h81.541c0,0,0-101.877,0-144.181c0-38.624,17.779-61.615,51.807-61.615
                                c31.268,0,46.289,22.071,46.289,61.615c0,39.545,0,144.181,0,144.181h84.605c0,0,0-100.344,0-173.915
                                s-41.689-109.131-99.934-109.131s-82.768,45.369-82.768,45.369V187.333z"
                      />
                    </svg>
                    <span class="screen-reader-text">linkedin</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      ref="headerEl"
      class="flex items-center justify-between flex-wrap px-4"
      :class="{ sticky: stickyHeader }"
      style="border-bottom:1px solid #e0e0e0;"
    >
      <div class="flex items-center flex-shrink-0">
        <img
          class="w-64 logo"
          alt="sms logo"
          src="../../assets/sms-logo-min.png"
        />
      </div>
      <div class="block lg:hidden">
        <button
          @click="toggleMenu"
          class="flex items-center px-3 py-2 border rounded border-red-400 hover:text-red-700 hover:border-red-700"
        >
          <svg
            class="fill-current h-3 w-3"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>
      <div
        :class="{ hidden: !showMenu }"
        class="w-full block flex-grow lg:flex lg:items-center lg:w-auto z-20 lg:pt-8 lg:px-0 lg:pb-0 px-6 pb-6"
      >
        <div class="text-sm lg:flex-grow lg:-mt-12">
          <router-link
            v-for="header in headerData"
            :key="header.name"
            class="pt-3 base-link block mt-4 lg:inline-block lg:mt-0 mr-4 lg:max-w-lg"
            :class="{
              'hover:text-red-700': !header.group,
              group: header.group,
              home: header.name === 'Home'
            }"
            :to="header.route"
          >
            <span v-if="!header.group" class="base-text">{{
              header.name
            }}</span>
            <button
              v-if="header.group"
              class="w-full flex items-center outline-none focus:outline-none hover:text-red-700"
            >
              <span class="base-text">{{ header.name }}</span>
            </button>
            <ul
              v-if="header.group"
              class="bg-gray-200 border rounded-sm transform scale-0 group-hover:scale-100 absolute 
          transition duration-150 ease-in-out origin-top min-w-32"
              style="max-width:16rem;z-index:10;"
            >
              <li
                v-for="subGroup in header.subGroups"
                :key="subGroup.name"
                class="rounded-sm relative hover:bg-gray-100"
                :class="{ 'hover:text-red-700': !subGroup.group }"
              >
                <router-link
                  v-if="!subGroup.group"
                  :to="(subGroup.skip ? '' : header.route) + subGroup.route"
                >
                  <div style="width:100%;" class="base-text px-3 py-1">
                    {{ subGroup.name }}
                  </div>
                </router-link>
                <button
                  :data-link="
                    (subGroup.skip ? '' : header.route) + subGroup.route
                  "
                  @click="openLink"
                  v-if="subGroup.group"
                  class="w-full text-left flex items-center outline-none focus:outline-none hover:text-red-700"
                >
                  <router-link
                    :to="(subGroup.skip ? '' : header.route) + subGroup.route"
                  >
                    <div
                      style="width:100%;"
                      class="pr-1 flex-1 base-text px-3 py-1"
                    >
                      {{ subGroup.name }}
                    </div>
                  </router-link>
                  <span class="mr-auto pointer-events-none">
                    <svg
                      class="fill-current h-4 w-4
                              transition duration-150 ease-in-out"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                      />
                    </svg>
                  </span>
                </button>
                <ul
                  v-if="subGroup.group"
                  :class="{ 'flow-left': header.flowLeft }"
                  class="bg-gray-200 border rounded-sm absolute top-0
                              transition duration-150 ease-in-out origin-top-left min-w-32"
                >
                  <li
                    v-for="subSubGroup in subGroup.subGroups"
                    :key="subSubGroup.name"
                    class="hover:bg-gray-100 hover:text-red-700"
                  >
                    <router-link :to="header.route + subSubGroup.route">
                      <div style="width:100%;" class="px-3 py-1 base-text">
                        <span>{{ subSubGroup.name }}</span>
                      </div>
                    </router-link>
                  </li>
                </ul>
              </li>
            </ul>
          </router-link>
          <router-link
            class="base-link block mt-4 lg:inline-block lg:mt-0 hover:text-red-700 mr-4 align-middle"
            to="/lo-ohm"
            ><img class="w-10" alt="lo-ohm" src="../../assets/Lo-Ohm.png"
          /></router-link>
          <router-link
            class="pt-3 base-link block mt-4 lg:inline-block lg:mt-0 hover:text-red-700 mr-4"
            to="/shop"
            ><span class="base-text">Shop</span></router-link
          >
          <router-link
            v-if="$store.getters['cart/isCartNotEmpty']"
            class="base-link-cart block mt-4 lg:inline-block lg:mt-0 hover:text-red-700 mr-4 align-middle"
            to="/cart"
            ><svg
              style="width:16px;"
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="shopping-cart"
              class="svg-inline--fa fa-shopping-cart fa-w-18"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 576 512"
            >
              <path
                fill="currentColor"
                d="M528.12 301.319l47.273-208C578.806 78.301 567.391 64 551.99 64H159.208l-9.166-44.81C147.758 8.021 137.93 0 126.529 0H24C10.745 0 0 10.745 0 24v16c0 13.255 10.745 24 24 24h69.883l70.248 343.435C147.325 417.1 136 435.222 136 456c0 30.928 25.072 56 56 56s56-25.072 56-56c0-15.674-6.447-29.835-16.824-40h209.647C430.447 426.165 424 440.326 424 456c0 30.928 25.072 56 56 56s56-25.072 56-56c0-22.172-12.888-41.332-31.579-50.405l5.517-24.276c3.413-15.018-8.002-29.319-23.403-29.319H218.117l-6.545-32h293.145c11.206 0 20.92-7.754 23.403-18.681z"
              ></path></svg
          ></router-link>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { headerData } from "./headerData.ts";

export default {
  name: "Header",
  props: {
    msg: String
  },
  data: function() {
    return {
      headerData,
      showMenu: false,
      stickyHeader: false
    };
  },
  mounted() {
    let headerEl = this.$refs.headerEl,
      sticky = headerEl.offsetTop;

    window.onscroll = () => {
      if (!this.stickyHeader && window.pageYOffset > sticky) {
        this.$emit("stickyUpdate", true);
        this.stickyHeader = true;
        this.showMenu = false;
      } else if (this.stickyHeader && window.pageYOffset <= sticky) {
        this.$emit("stickyUpdate", false);
        this.stickyHeader = false;
        this.showMenu = false;
      }
    };
  },
  methods: {
    openLink() {
      if (event.target.nodeName === "BUTTON") {
        if (this.$router.currentRoute.path !== event.target.dataset.link) {
          this.$router.push({ path: event.target.dataset.link });
        }
        event.preventDefault();
        event.stopPropagation();
      }
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    }
  }
};
</script>

<style lang="scss" src="./Header.scss"></style>
