/* Maybe use this for the cart instead. */

// initial state
const state = () => {
  let sessionCart = sessionStorage.getItem("cart"),
    cart = sessionCart ? JSON.parse(sessionCart) : {};

  return {
    cart: cart,
    //HERE
    flatRate: {
      '4" Complete Shipping Container': 100,
      '6" Complete Shipping Container': 120,
      "koenen tubes": 20,
      "Small Conductive Tools": 5,
      "Large Conductive Tools": 17,
      "Larger Conductive Tools": 43,
      '4" Liquid Shipping Pipe': 92,
      'o-4" Complete Shipping Container': 220,
      'o-6" Complete Shipping Container': 220,
      "o-Small Conductive Tools": 5,
      "o-Large Conductive Tools": 10,
      'o-4" Liquid Shipping Pipe': 220,
      "with-conditions": 8,
      "with-conditions-buckets": 8,
      "with-conditions-buckets2": 8
    },
    farState: false,
    tax: false
  };
};

// getters
const getters = {
  tax: state => {
    return state.tax;
  },
  cart: state => {
    return state.cart;
  },
  isCartNotEmpty: state => {
    let cart = JSON.parse(JSON.stringify(state.cart));
    return Object.keys(cart).length ? true : false;
  },
  cartDetail: state => {
    let shippingWithConditions = 0,
      shippingWithConditionsBuckets = 0;
    let res = Object.keys(state.cart).reduce(
      (detail, key) => {
        let item = state.cart[key],
          amt = item.product["Regular price"] * item.amount,
          ind = "";
        detail.subtotal += amt;
        if (item.product["Tax status"] === "taxable" && state.tax) {
          detail.tax += amt * 0.047;
        }
        if (state.farState) {
          ind = "o-";
        }
        if (item.product["Shipping class"] === "with-conditions") {
          shippingWithConditions += amt;
        } else if (
          item.product["Shipping class"] === "with-conditions-buckets"
        ) {
          if (item.product.Name.indexOf("2 Gallon") >= 0) {
            shippingWithConditionsBuckets =
              shippingWithConditionsBuckets < 50
                ? 50
                : shippingWithConditionsBuckets;
          } else if (item.product.Name.indexOf("4 Gallon") >= 0) {
            shippingWithConditionsBuckets = 75;
          }
        } else {
          detail.shipping +=
            item.amount * state.flatRate[ind + item.product["Shipping class"]];
        }
        return detail;
      },
      {
        subtotal: 0,
        tax: 0,
        shipping: 0
      }
    );
    let largeBoxes = Math.floor(shippingWithConditions / 65),
      mediumBoxes = 0,
      remaining = shippingWithConditions % 65;
    if (remaining >= 21) {
      largeBoxes += 1;
    } else if (remaining >= 8) {
      mediumBoxes += 1;
    }

    let conditionAmount = largeBoxes * 21.5 + mediumBoxes * 16.1;
    if (conditionAmount > 0) {
      conditionAmount += 2;
    }
    res.shipping += conditionAmount;

    // Shipping with buckets
    res.shipping += shippingWithConditionsBuckets;

    res.tax = parseFloat(res.tax.toFixed(2));
    res.total = res.subtotal + res.tax + res.shipping;

    return res;
  }
};

// actions
const actions = {
  removeItem(context, key) {
    let cart = JSON.parse(JSON.stringify(context.state.cart));
    delete cart[key];
    context.commit("setCart", cart);
  },
  addToCart(context, detail) {
    let cart = JSON.parse(JSON.stringify(context.state.cart)),
      item = detail.item,
      count = detail.count;

    if (!cart[item.ID]) {
      cart[item.ID] = {
        amount: 0,
        product: item
      };
    }
    cart[item.ID].amount += count;

    context.commit("setCart", cart);
  },
  updateCartAmount(context, detail) {
    let cart = JSON.parse(JSON.stringify(context.state.cart));
    cart[detail.key].amount += detail.change;
    if (cart[detail.key].amount < 1) {
      cart[detail.key].amount = 1;
    }
    context.commit("setCart", cart);
  }
};

// mutations
const mutations = {
  setCart(state, cart) {
    state.cart = cart;
    sessionStorage.setItem("cart", JSON.stringify(cart));
  },
  setFarState(state, farstate) {
    state.farState = farstate;
  },
  setTax(state, isTax) {
    state.tax = isTax;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
